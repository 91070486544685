import React from "react"
import { Link, graphql } from "gatsby"
//
// import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribtionForm from "../components/subscribtionForm"


const BlogIndex = ({ data, location }) => {
  const { title, description} = data.site.siteMetadata

  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location} title={title} description={description}>
      <SEO title="Cards" description="Short-form ideas" />
      <div className="lg:px-16 px-2 mt-4">
        <SubscribtionForm />
      </div>
      <div className="hidden" id="api-cards">{posts.length}</div>

      <main className="mx-2 py-10 lg:mx-56 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">

        {posts.map(({ node }) => {
          const postTitle = node.frontmatter.title || node.fields.slug

          const thumbnailBackground =
            node.frontmatter.card.childImageSharp.fluid

          return (
            <article
              className="mb-6 max-w-full"
              key={node.fields.slug}
            >
              <Link to={node.fields.slug}>
                <BackgroundImage
                  className="h-56 flex-none rounded-t-sm border-r border-t border-l border-b-0 text-center  border-mediumgrey"
                  fluid={thumbnailBackground}
                />
              </Link>

              <Link to={node.fields.slug}>
                <div className="border-r border-b border-l border-t-0 border-mediumgrey bg-white rounded-b-sm p-4 flex flex-col justify-between leading-normal cursor-pointer">
                  <div className="mb-4">
                    <header>
                      <h3 className="w-full mt-2 text-xs font-sans text-predarkgrey">
                        {node.frontmatter.number}
                      </h3>
                      <h3 className="w-full text-2xl font-serif font-black text-darkgrey leading-tight line-clamp-2">
                        {postTitle}
                      </h3>
                    </header>
                  </div>
                </div>
              </Link>

            </article>
          )
        })}
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___number], order: DESC }
      filter: { frontmatter: { type: { eq: "card" } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            number
            card {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
